import accessibleAutocomplete from 'accessible-autocomplete'
import AddressLookup from '../../address_autocomplete/address_lookup.js'
import {Controller} from "@hotwired/stimulus"
import {showHide} from "../../govuk/visibility.js"
import {debounce} from "../debounce.js";

export default class extends Controller {
  #addressLookup
  #autocompleteInput
  #searching
  #debouncing = false

  NOT_IMPLEMENTED = 'Not implemented in base address class'

  connect() {
    this._id = this.firstInputElement.id

    this.handleQueryDebounced = debounce(async (query, populateResults) => {
      await this.handleQuery(query, populateResults);
    }, this.debounceMsValue);

    this.debouncedSource = async (query, populateResults) => {
      this.#debouncing = true;
      try {
        await this.handleQueryDebounced(query, populateResults);
      } finally {
        this.#debouncing = false;
      }
    }

    accessibleAutocomplete({
      element: this.autocompleteRootTarget,
      id: this._id, // To match it to the existing <label>.
      tNoResults: () => this.#debouncing ? this.searchingValue : this.noResultsValue,
      source: this.debouncedSource.bind(this),
      onConfirm: this.addressChanged.bind(this)
    })

    this.searching = this.shouldStartInSearchMode()
  }

  get firstInputElement() { throw(this.NOT_IMPLEMENTED) }
  shouldStartInSearchMode() { throw(this.NOT_IMPLEMENTED) }
  set completedAddress(value) { throw(this.NOT_IMPLEMENTED) }

  toggleSearching(e) {
    e.preventDefault()
    this.searching = !this.searching
  }

  get searching() {
    return this.#searching
  }

  set searching(value) {
    this.#searching = value

    this.searching ?  this.hideAddressFields() : this.showAddressFields()
    this.setVisibilities()
  }

  showAddressFields() {
    this.autocompleteInput.id = null
  }

  hideAddressFields() {
    this.autocompleteInput.id = this._id
    this.autocompleteInput.value = null;
  }

  get suggestions() {
    return this._suggestions
  }

  set suggestions(value) {
    this._suggestions = value
  }

  // The autocomplete input itself, once created
  get autocompleteInput() {
    if(!this.#autocompleteInput) {
      this.#autocompleteInput = this.autocompleteRootTarget.querySelector('input.autocomplete__input')
    }

    return this.#autocompleteInput
  }

  get addressLookup() {
    if (this.#addressLookup === undefined) {
      this.#addressLookup = new AddressLookup(this.countryValue)
    }

    return this.#addressLookup
  }

  invalidateAddressLookup() {
    this.#addressLookup = undefined
    // Stimulus can call this via countryValueChanged() before connect().
    // There isn't an autocompleteInput with a value to clear at that time.
    if(this.autocompleteInput) {
      this.autocompleteInput.value = ''
    }
  }

  async handleQuery(query, populateResults) {
    this.suggestions = await this.addressLookup.fetchSuggestions(query)
    const addresses = this.suggestions.map(suggestion => suggestion.displayName.toString())
    populateResults(addresses)
  }

  // Return a full address record
  async addressChanged(key) {
    if(key === undefined) {
      return
    }

    const suggestion = this.suggestions.find(suggestion => suggestion.displayName === key )

    this.completedAddress = await this.addressLookup.fetchResolvedAddress(suggestion.id)
    this.searching = false
  }

  setVisibilities() {
    this.visibleSearchingTargets.forEach(el => showHide(el, this.searching))
    this.visibleManualTargets.forEach(el => showHide(el, !this.searching))
  }
}
